<template>
  <div>
    <b-card>
      <b-row class="align-items-end">
        <b-col md="3"
          v-if="checkRoleUser"
        >
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.Branches')}}
            </p>
            <div class="wrapper-form">
              <v-select
                class="w-100"
                label="name"
                v-model="filter.branch_id"
                :options="branchesOptions"
                @input="getDataByFilter"
              ></v-select>
            </div>
          </div>
        </b-col>

        <b-col md="3">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.tableHeader.date')}}
            </p>
            <div class="wrapper-form">
              <input type="date" class="form-control" v-model="filter.date" @input="getDataByFilter">
            </div>
          </div>
        </b-col>


        <b-col md="4" class="ml-auto">
          <div class="d-flex justify-content-end w-100">
            <b-button variant="primary" @click="getDataByFilter">
              {{$t('message.seachLabel')}}
            </b-button>
            <b-button variant="info" class="ml-1" @click="reportModal=true">
              {{$t('Report')}}
            </b-button>

            <b-button variant="primary" class="ml-2" @click="goToRouter">
              {{$t('message.Add')}}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <div class="w-100 d-flex justify-content-end">

    </div>
    <b-card class="mt-3">
      <b-table :fields="fields" :items="visa" responsive :busy="busy">
        <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
        <template #cell(id)="data">
          <span class="text-md text-primary"> {{ ((parseInt(pagination.page) - 1) * parseInt(pagination.pageSize)) + (parseInt(data.index) + 1) }}</span>
        </template>
        <template #cell(client)="{item}">
          <div class="mb-1">{{item.client.full_name}}</div>
          <div class="w-100 text-info" v-for="(contact,index) in JSON.parse(item.client.contacts)" :key="index">
            {{contact.number}}
          </div>
        </template>

        <template #cell(market_price)="{item}">
          <span>
            {{parseInt(item.market_price)}}
          </span>
        </template>

        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <b-button
                variant="outline-success"
                size="sm"
                @click="handleShow(data.item.id)"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-warning" size="sm" @click="handleUpdate(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal v-model="reportModal" hide-footer>
      <b-overlay :show="showLoading">
        <div class="w-100">
          <b-form-input v-model="report.name"></b-form-input>
        </div>
        <div class="w-100 mt-2">
          <input type="date" class="form-control" v-model="report.start_date">
        </div>
        <div class="w-100 mt-2">
          <input type="date" class="form-control" v-model="report.end_date">
        </div>

        <div class="w-100 mt-3 d-flex justify-content-end">
          <b-button variant="success" @click="generateExcel">{{$t('GenerateReport')}}</b-button>
        </div>
      </b-overlay>
    </b-modal>

    <b-pagination v-model="pagination.page" :total-rows="total" :per-page="pagination.pageSize" @input="fetchVisa">
    </b-pagination>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import { deleteVisa, getVisaExcel, getVisas } from '@/api/visa/index'
  import {getBranches} from '@/api/branchs/branchs'
  import moment from 'moment';
  import {mapGetters} from 'vuex'
  import { getTourExcel } from '@/api/reports/tours'

  export default {
    name: 'Visa',
    data() {
      return {
        id: null,
        fields: [
          {
            key: 'id',
            label: '№'
          },
          {
            key: 'client',
            label: this.$t('message.Client')
          },
          {
            key: 'country.name_ru',
            label: this.$t('message.Countries')
          },
          {
            key: 'price',
            label: this.$t('message.Price')
          },
          {
            key: 'fee',
            label: this.$t('message.Fee')
          },
          {
            key: 'currency.currency',
            label: this.$t('message.Currency')
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        visa: [],
        pagination: {
          page: 1,
          pageSize: 15,
        },
        total: 1,

        filter: {
          branch_id: null,
          date: null,
        },
        filters: {
          branch_id: null,
          date: null
        },
        branchesOptions: [],
        report:{
          name: '',
          start_date: null,
          end_date: null,
        },
        showLoading: false,
        reportModal:false,
        busy: true,
      }
    },
    computed: {
      ...mapGetters('auth', ['activeUser']),
      checkRoleUser() {
        if(this.activeUser !== null) {
          return this.activeUser.roles.some(item => item.name == 'SuperAdmin')
        }
        return false
      }
    },
    methods: {
      fetchVisa() {
        getVisas({
          ...this.pagination,
          ...this.filters,
          relations: true
        }).then(response => {
          this.visa = response.data.data;
          this.total = response.data.total;
          this.busy = false;
        })
      },
      handleUpdate(id) {
        this.$router.push({
          name: 'visa-update',
          params: {
            visaId: id
          }
        })
      },
      generateExcel() {
        this.showLoading = true;
        getVisaExcel(this.report).then(({data}) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'visa-report-excel.xlsx');
          document.body.appendChild(link);
          link.click()
          this.showLoading = false;
          this.reportModal=false
        })
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteVisa({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchVisa()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
      goToRouter() {
        this.$router.push({
          name: 'visa-add'
        })
      },
      getBranchesOptions() {
        getBranches().then( res => {
          this.branchesOptions = res.data.data
        })
      },
      getDataByFilter() {
        this.filters.branch_id = this.filter.branch_id ? this.filter.branch_id.id : null;
        this.filters.date = this.filter.date ? moment(this.filter.date).format('YYYY-MM-DD') : null;
        this.fetchVisa();
      },
      handleShow(id) {
        this.$router.push({name: 'visa-details', params: {visaDetailsId: id}})
      }
    },
    created() {
      this.getBranchesOptions();
      this.fetchVisa();
    }
  }
</script>
